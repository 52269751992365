@font-face {
    font-family: 'Euclid Circular A';
    src: url('../font/italic/EuclidCircularA-BoldItalic.eot');
    src: url('../font/italic/EuclidCircularA-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/italic/EuclidCircularA-BoldItalic.woff2') format('woff2'),
        url('../font/italic/EuclidCircularA-BoldItalic.woff') format('woff'),
        url('../font/italic/EuclidCircularA-BoldItalic.ttf') format('truetype'),
        url('../font/italic/EuclidCircularA-BoldItalic.svg#EuclidCircularA-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../font/semiBold/EuclidCircularA-SemiBold.eot');
    src: url('../font/semiBold/EuclidCircularA-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../font/semiBold/EuclidCircularA-SemiBold.woff2') format('woff2'),
        url('../font/semiBold/EuclidCircularA-SemiBold.woff') format('woff'),
        url('../font/semiBold/EuclidCircularA-SemiBold.ttf') format('truetype'),
        url('../font/semiBold/EuclidCircularA-SemiBold.svg#EuclidCircularA-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../font/medium/EuclidCircularA-Medium.eot');
    src: url('../font/medium/EuclidCircularA-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/medium/EuclidCircularA-Medium.woff2') format('woff2'),
        url('../font/medium/EuclidCircularA-Medium.woff') format('woff'),
        url('../font/medium/EuclidCircularA-Medium.ttf') format('truetype'),
        url('../font/medium/EuclidCircularA-Medium.svg#EuclidCircularA-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../font/medium-italic/EuclidCircularA-MediumItalic.eot');
    src: url('../font/medium-italic/EuclidCircularA-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/medium-italic/EuclidCircularA-MediumItalic.woff2') format('woff2'),
        url('../font/medium-italic/EuclidCircularA-MediumItalic.woff') format('woff'),
        url('../font/medium-italic/EuclidCircularA-MediumItalic.ttf') format('truetype'),
        url('../font/medium-italic/EuclidCircularA-MediumItalic.svg#EuclidCircularA-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../font/regular/EuclidCircularA-Regular.eot');
    src: url('../font/regular/EuclidCircularA-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/regular/EuclidCircularA-Regular.woff2') format('woff2'),
        url('../font/regular/EuclidCircularA-Regular.woff') format('woff'),
        url('../font/regular/EuclidCircularA-Regular.ttf') format('truetype'),
        url('../font/regular/EuclidCircularA-Regular.svg#EuclidCircularA-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

body {
    font-family: 'Euclid Circular A';
    margin: 0;
    overflow-x: hidden;
}

ul {
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

p {
    font-size: 18px;
    line-height: 1.4em;
}

.secTitle {
    font-size: 80px;
    line-height: 1;
    margin: 0;
    color: #002147;
    padding-bottom: 80px;
    text-align: center;
}

.textWhite {
    color: #fff;
}

h4 {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    line-height: 18px;
}

h3 {
    font-size: 34px;
    color: #001C54;
    line-height: 1;
}

.row {
    display: flex;
    width: 100%;
}

.container {
    max-width: 1400px;
    margin: 0 auto;

}

* {
    box-sizing: border-box;
}

.container-full {
    max-width: 100%;
    line-height: 0;
}

.textUpper {
    text-transform: uppercase;
}

img {
    width: 100%;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.p-80-20 {
    padding: 80px 20px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-32 {
    font-size: 32px !important;
}

.fs-34 {
    font-size: 34px !important;
}

.fs-36 {
    font-size: 36px !important;
}

.fs-42 {
    font-size: 42px !important;
}

.fs-80 {
    font-size: 80px !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.lineHeight1 {
    line-height: 1;
}

.textOrg {
    color: #FB4F22;
}

.textBlack {
    color: #000 !important;
}

.textLOrg {
    color: #F9A21B !important;
}

.orgBg {
    background-color: #F9A21B !important;
}

.bgBlack {
    background-color: #000;
}

.bglightBlack {
    background-color: #212529;
}

.widthAuto {
    width: auto;
}



/* oxford mun */
.oxfordBanner {
    position: relative;
    max-height: 100vh;
    overflow: hidden;
}

/* .oxfordBanner::before {
    content: " ";
    background: linear-gradient(180deg, #382B28 0%, #382b2880 70%, #382c2900 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
} */

.oxfordBanner .banner-content h1 {
    font-size: 80px;
    font-weight: 600;
    line-height: 1;
    z-index: 2;
}

.oxfordBanner::after {
    content: " ";
    background: linear-gradient(180deg, #00000000 0%, #00000080 70%, #000000 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 270px;
}

.oxfordBanner .banner-content .muteBtn {
    float: right;
    background-color: #fff;
    border-radius: 50px;
    margin-right: 30px;
    color: #002147;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    width: 40px;
    height: 40px;
    justify-content: center;
}

.oxfordBanner .banner-content .muteBtn img {
    width: auto;

}

.oxfordBanner .banner-content .muteBtn {
    float: right;
    background-color: #fff;
    border-radius: 50px;
    margin-right: 30px;
    color: #002147;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    width: 40px;
    height: 40px;
    justify-content: center;
}

.oxfordBanner .banner-content .muteBtn img {
    width: auto;

}

.oxfordBanner .banner-content .mute img.unmuteIcon {    
    display: none;
}

.oxfordBanner .banner-content .unmute img.unmuteIcon {    
    display: block;
}

.oxfordBanner .banner-content .unmute img.muteIcon {    
    display: none;
}

.oxfordBanner .banner-content .mute img.muteIcon {    
    display: block;
}

.banner-content {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 35px;
    color: #fff;
    z-index: 2;
}

.intro-section {
    background-color: #F5FCFF;
    padding: 80px 20px 80px 20px;
}

.introduction h2 {
    padding-bottom: 30px;
}

.introduction p {
    width: 850px;
    margin: 0 auto;
    text-align: center;
}

/* Why Oxford MUN India ? */


.whyOxfordSec {
    background-image: url(../../../public/assets/images/blue-bg.png);
    background-repeat: no-repeat;
    padding: 150px 20px 150px 20px !important;
    background-position: center center;
    background-size: cover;
}

.whyOxfordSec .inner-box {
    border-radius: 50px;
    padding: 50px;
    background-color: #fff;
}

.whyOxfordSec .row {
    gap: 120px;
}

.column {
    width: 50%;
}

.whyOxfordSec .oxford-mun h2 {
    color: #fff;


}

.whyOxfordSec .oxford-box h3 {
    width: 350px;
    padding-bottom: 20px;
    line-height: 1.2;

}

.whyOxfordSec .column .oxford-box p {
    padding-right: 50px;
}

.whyOxfordSec .column .oxford-box:first-child {
    padding-bottom: 40px;
    border-image: linear-gradient(to left, #fff, #66666680, #666666cc, #666666) 1;
    border-bottom: 2px solid;
    margin-bottom: 40px;
}


/* Conference Details */
.confere-detail {
    padding: 0px 90px;
}

.conference {
    padding: 100px 20px;
}

.confere-box {
    border: 3px solid #002147;
    border-radius: 22px;
    padding: 55px 45px;
    margin-bottom: 34px;
    position: relative;
}

.confere-box h3 {
    padding-bottom: 25px;
    text-transform: uppercase;
}

.conference .confere-box p {
    color: #4f4f4f;
    font-weight: 500;
    padding-bottom: 5px;
}

.conference .confere-box span.bgColorText {
    background-color: #072654;
    font-size: 18px;
    line-height: 2;
    padding: 3px 10px;
    font-weight: 600;
    color: #fff;
    font-style: italic;
}

.conference .confere-detail .confere-box:before {
    content: " ";
    position: absolute;
    right: 25px;
    top: 0px;
    width: 16px;
    height: 100%;
    background-color: #002147;
}

.conference .confere-detail .row {
    gap: 40px;
}

.conference .confere-reg {
    background-color: #002147;
    border-radius: 22px;
    padding: 30px;
    display: inline-flex;
    align-items: center;
}

.conference .confere-reg p {
    color: #DDF5FF;
    padding-bottom: 45px;
    line-height: 1.6;
    font-weight: 500;
}

.conference .confere-reg .header-btn {
    margin-left: 0px;
}

.conference .confere-reg h3 {
    color: #DDF5FF;
    padding-bottom: 20px;
    text-transform: uppercase;
}

.conference .confere-reg p span {
    background-color: #ddf5ff;
    color: #002147;
    padding: 0 3px;
    font-weight: 600;
    font-style: italic;
}

.registerBtn {
    background-color: #FB4F22;
    padding: 15px 35px;
    color: #fff;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    text-decoration: none;
    line-height: 1;
    display: inline-block;
}

.eventSec {
    padding: 100px 20px;
}

.eventSec .row {
    gap: 60px;
}

.eventSec .eventBox {
    background-color: #fff;
    border-radius: 20px;
    width: auto;
    margin: 0 auto;
}

.eventSec .eventBox .eventContentBox {
    padding: 50px 25px 40px;
    position: relative;
    min-height: 231px;
}

.eventSec .eventBox img {
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 280px;
    object-fit: cover;
}

.eventSec .eventBox .eventTitle {
    font-size: 28px;
    font-weight: 700;
    color: #002147;
    line-height: 1;
    padding-bottom: 10px;
}

.eventSec .eventBox p {
    color: #4F4F4F;
    font-weight: 500;
}

.eventSec .eventBox .eventDate {
    font-size: 28px;
    font-weight: 700;
    line-height: 1;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: -50px;
    background-color: #002147;
    color: #fff;
    padding: 15px;
    border-radius: 6px;
    border: 2px solid #fff;
}
/* Css  */
.eventSec .eventBox .eventDate span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    padding-top: 10px;
}

.partnerSec {
    padding: 100px 0px;
    overflow: hidden;
}

.partnerSec .partnerLogoGrid {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(9, 1fr);
}

.partnerSec .partnerLogoGrid .partnerLogo {
    display: inline-flex;
    justify-content: center;
}

.partnerSec .partnerLogoGrid .partnerLogo img {
    width: 100%;
}

.venueSpotSec {
    padding: 50px 20px 50px;
}

.venueSpotSec .venueSpot-inner {
    display: flex;
    align-items: center;
}

.venueSpotSec .venueSpot-inner .venueSpotContent h2 {
    font-size: 42px;
    font-weight: 600;
    line-height: 1;
    padding-bottom: 30px;
    color: #072654;
}

.venueSpotSec .venueSpot-inner .venueSpotContent {
    padding: 60px 70px 60px 140px;
    border: 5px solid #072654;
    margin-left: -70px;
    position: relative;
    z-index: 2;
}

.venueSpotSec .venueSpot-inner .image-gallery-slides {
    border-radius: 30px;
}

.venueSpotSec .venueSpot-inner img {
    width: 770px;
    object-fit: cover;
}

.venueSpotSec .venueSpot-inner .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: 100% !important;
    border-radius: 10px;
}

.venueSpotSec .venueSpot-inner .image-gallery-thumbnail.active .image-gallery-thumbnail-image {
    border: 2px solid #FFF;
}

.venueSpotSec .venueSpot-inner .image-gallery-thumbnail {
    width: 150px;
    margin: 0px 10px;
    border: none;

}

.venueSpotSec .venueSpot-inner .image-gallery-thumbnails-wrapper {
    position: absolute;
    bottom: 10px;
    left: 20px;
    overflow-x: scroll;
}

.venueSpotSec .venueSpot-inner .image-gallery-content {
    overflow: hidden;
}

.venueSpotSec .venueSpot-inner .image-gallery-thumbnails-wrapper::-webkit-scrollbar {
    display: none;
}

.venueSpotSec .venueSpot-inner .image-gallery-left-nav,
.image-gallery-right-nav {
    padding: 0px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffffff80;
    border-radius: 50px;
    width: 40px;
    height: 40px;
}

.venueSpotSec .venueSpot-inner .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 30px;
    width: 30px;
    color: #072654;
}

.venueSpotSec .venueSpot-inner .image-gallery-right-nav {
    right: 15px;
}

.venueSpotSec .venueSpot-inner .image-gallery-left-nav {
    left: 15px;
}

.testimonialSec {
    padding: 50px 20px 100px;
    overflow-x: hidden;
}

.testimonialSec .testimonial-inner {
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(5, 1fr);
}

.testimonialSec .testimonialImg img {
    width: 100%;
}

.testimonialSec .testimonialImg .textWrapper img {
    width: 40px;
    display: block;
    padding-bottom: 25px;
}

.testimonialSec .testimonialImg {
    position: relative;
    line-height: 0;
}

.testimonialSec .testimonialImg:before {
    content: " ";
    position: absolute;
    background-color: #072654;
    height: 65px;
    width: 65px;
    bottom: -10px;
    left: -10px;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

.testimonialSec .testimonialImg:after {
    content: " ";
    position: absolute;
    top: -8px;
    right: -10px;
    width: 110px;
    height: 64px;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    background-image: url(../../../public/assets/images/testlBgShape.svg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0;
    visibility: hidden;
}

.testimonialSec .testimonialImg:hover::before,
.testimonialSec .testimonialImg:hover::after {
    opacity: 1;
    visibility: visible;
}

.testimonialSec .testimonialImg .textWrapper {
    position: absolute;
    bottom: 40px;
    left: 50%;
    width: 100%;
    z-index: 2;
    text-align: -webkit-center;
    transform: translate(-50%);
    transition: all 0.5s ease-in-out;

}

.testimonialSec .testimonialImg .titleWatch {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    opacity: 0;
    visibility: hidden;

}

.testimonialSec .testimonialImg:hover .titleWatch {
    opacity: 1;
    visibility: visible;
}

.testimonialSec .testimonialImg:hover .textWrapper:before {
    height: 100px;
}

.testimonialSec .testimonialImg .textWrapper:before {
    content: " ";
    position: absolute;
    bottom: -40px;
    left: 0px;
    width: 100%;
    height: 0px;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 8%, rgba(0, 0, 0, 0.6) 100%);
}

.reviewSec {
    padding: 100px 0px 0px;
    position: relative;
}

.reviewSec .reviewContent {
    background-color: #fff;
    border-radius: 80px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
    padding: 30px 50px;
    width: 610px;
    /* width: 560px; */
}

.reviewSec .reviewContent .reviewTitle {
    text-align: center;
    font-weight: 700;
}

.reviewSec .reviewContent p {
    font-size: 16px;
    text-align: center;
    padding: 0 0px;
}

.reviewSec .reviewContent p span {
    display: block;
    font-size: 22px;
    font-weight: 600;
}

/* .reviewSec .reviewContent:before {
    content: " ";
    position: absolute;
    left: 30px;
    top: 50%;
    width: 14px;
    height: 20px;
    transform: translateY(-50%);
    background-image: url("../../../public/assets/images/leftArrow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.reviewSec .reviewContent::after {
    content: " ";
    position: absolute;
    right: 30px;
    top: 50%;
    width: 14px;
    height: 20px;
    transform: translateY(-50%);
    background-image: url("../../../public/assets/images/rightArrow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
} */


.reviewSec .swiper-button-prev:after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 20px;
    background-image: url("../../../public/assets/images/leftArrow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.reviewSec .swiper-button-prev {
    position: absolute;
    left: calc(50% - 280px);
    margin: 0px;
    top: initial;
    bottom: 76px;
    width: 14px;
    height: 20px;
}

.reviewSec .swiper-button-next::after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 20px;
    background-image: url("../../../public/assets/images/rightArrow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.reviewSec .swiper-button-next {
    position: absolute;
    right: calc(50% - 280px);
    margin: 0px;
    top: initial;
    bottom: 76px;
    width: 14px;
    height: 20px;
}

.joinSec {
    padding: 0 20px;
    background-image: url("../../../public/assets/images/bluebg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.joinSec .logoImage img {
    width: 415px;
}

.joinSec .logoImage {
    text-align: -webkit-center;
}

.joinSec .content-wrapperCol {
    display: flex;
    align-items: center;
}

.joinSec .content-wrapperCol .content-wrapper .contentTitle {
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.3;
    padding-bottom: 10px;
    margin-bottom: 30px;
    position: relative;
}

.joinSec .content-wrapperCol .content-wrapper .contentTitle:after {
    content: " ";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 230px;
    height: 5px;
    background-color: #fff;
    border-radius: 30px;
}

.joinSec .content-wrapperCol .content-wrapper p {
    color: #fff;
    font-weight: 500;
    line-height: 1.6;
    padding-bottom: 30px;
}

/* .joinSec .content-wrapperCol .content-wrapper .registerBtn {
    background-color: #fff;
    color: #002147;
} */

.callToAction {
    padding: 100px 20px;
}

.callToAction .callToAction-inner {
    padding: 80px 100px;
    background-image: url("../../../public/assets/images/readyBg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 50px;

}

.callToAction .callToAction-inner h5 {
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    padding-bottom: 15px;
}

.callToAction .callToAction-inner p {
    color: #fff;
    font-weight: 500;
    line-height: 1.6;
}

.callToAction .callToAction-inner .callToActionLeft p {
    width: 300px;
    padding: 0;
}

.callToAction .callToAction-inner .callToActionRight p {
    width: 100%;
    padding-bottom: 60px;
}

.faqSec {
    padding: 0px 20px 100px;
}

.faqSec .faqInner {
    padding: 80px;
    background: #F5FCFF;
    border-radius: 22px;
}

.faqSec .faqInnerLeft {
    width: 45%;
    margin-right: 30px;
    border-radius: 10px;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../public/assets/images/faqBg.png");
    display: inline-flex;
    align-items: center;
}

.faqSec .faqInnerRight {
    width: 55%;
    padding-left: 30px;
}

.faqSec .faqInnerRight .secTitle {
    text-align: left;
    padding-bottom: 30px;
}

.faqSec .faqInnerLeft img {
    width: 170px;
    padding-bottom: 15px;
}

.faqSec .faqInner p {
    color: #fff;
    font-weight: 500;
    padding-bottom: 50px;
    line-height: 1.6;
}

.faqSec .faqInnerRight .faqBlock .accordion {
    border: none;
    border-radius: 0;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__button {
    background: transparent;
    color: #000;
    padding: 40px 20px;
    position: relative;
    box-sizing: border-box;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__item {
    border-bottom: 1px solid #00000080;
    border-top: none;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__button .faqTitle {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    display: inline-flex;
    align-items: center;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__button .faqTitle img {
    width: auto;
    margin-right: 15px;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__button:before {
    position: absolute;
    left: initial;
    right: 0px;
    width: 15px;
    height: 15px;
    background-image: url("../../../public/assets/images/plus.svg");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    transform: rotate(0);
    border: none;
    margin: 0;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__panel p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    padding: 0px;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__panel p>a {
    color: #fb4f22;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__panel {
    padding: 0px 50px 30px;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__button[aria-expanded='true']::before,
.faqSec .faqInnerRight .faqBlock .accordion .accordion__button[aria-selected='true']::before {
    background-image: url("../../../public/assets/images/minus.svg");
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__panel {
    max-height: 200px;
    padding: 0px 50px 30px;
    opacity: 1;
    transition: all 1s linear;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__panel[hidden] {
    max-height: 0px;
    overflow: hidden;
    transition: all 1s linear;
    display: block;
    opacity: 0;
    padding: 0px 50px 0px;
}

.faqSec .faqInnerLeft .contactDetail img {
    width: auto;
    display: inline-block;
    padding: 0px 20px 0px 0px;
}

.faqSec .faqInnerLeft .contactDetail a {
    display: inline-flex;
    width: 100%;
    align-items: center;
    color: #fff;
    padding-top: 20px;
}

.faqSec .faqInnerLeft .socialLinks img {
    width: 30px;
    padding: 0px;
}

.faqSec .faqInnerLeft .socialLinks {
    padding-top: 20px;
}

.faqSec .faqInnerLeft .socialLinks a+a {
    margin-left: 20px;
}

.faqSec .faqInnerLeft a.registerBtn {
    margin-bottom: 30px;
}

/* ------------------------------------------------------------------------------------------------------- */

.topHeading {
    text-align: center;
    padding-bottom: 50px;
}

.topHeading h6,
.topHeading h2 {
    line-height: 1;
}

.bgOrg {
    background-color: #FB4F22;
}

.bgYellow {
    background-color: #FFBC00;
}

.bgSkyBlue {
    background-color: #2EBBDF;
}

.bgDarkRed {
    background-color: #A21B43;
}

.ourPillarsSec .ourPillars-outer {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
}

.ourPillarsSec .ourPillars-outer .pillars {
    position: relative;
}

.ourPillarsSec .ourPillars-outer .pillars .bgPillarsImg {
    width: 100%;
    height: 735px;
    /* height: 100%; */
    object-fit: cover;
}

.ourPillarsSec .ourPillars-outer .titleTop {
    position: absolute;
    top: 30px;
    left: 31px;
}

.ourPillarsSec .ourPillars-outer .titleTop h4 {
    display: inline-block;
    padding: 5px 10px;
    margin-bottom: 20px;
}

.ourPillarsSec .ourPillars-outer .content {
    position: absolute;
    bottom: 30px;
    left: 0px;
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    padding: 0px 30px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.ourPillarsSec .ourPillars-outer .content p {
    padding-right: 10px;
}

a.readMoreIconBtn {
    background-color: #FB4F22;
    padding: 25px 23px;
    border-radius: 50px;
    line-height: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;

}

.ourPillarsSec .ourPillars-outer .pillars:hover .content {
    opacity: 1;
}

.feacProgramSec {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.feacProgramSec .programContent h6 {
    color: #989292;
    padding-bottom: 5px;
}

.feacProgramSec .container-full {
    overflow: hidden;
}

.feacProgramSec .feacProgram-outer {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    margin-left: -70px;
}

.feacProgramSec .swiper-slide {
    height: 363px;
    width: 570px !important;
}

.feacProgramSec .swiper.feacProgram-outerCenter {
    margin: 30px 0px 30px 0px;
}

.feacProgramSec .sliderOne .sliderBgImage {
    width: 100%;
    height: 363px;
    object-fit: cover;
    border-radius: 50px;
}

.feacProgramSec .swiper .swiper-wrapper {
    margin-left: -70px;
}

.feacProgramSec .swiper.feacProgram-outerCenter .swiper-wrapper {
    margin-left: -270px;
}

.feacProgramSec .sliderOne {
    position: relative;
}

.feacProgramSec .sliderOne .programContent-inner {
    padding-right: 50px;
}

.feacProgramSec .sliderOne .programContent {
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding: 0 30px 30px;
    display: inline-flex;
    align-items: flex-end;
    z-index: 1;
}

.feacProgramSec .sliderOne .programContent-inner p {
    max-width: 265px;
}

.feacProgramSec .sliderOne:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 1) 100%);
    z-index: 0;
    opacity: 0.4;
    border-radius: 50px;
}

.feacProgramSec .sliderOne .programContent a.readMoreIconBtn {
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.feacProgramSec .sliderOne:hover a.readMoreIconBtn {
    opacity: 1;
}

/* .feacProgramSec .feacProgram-outerCenter {
    margin: 30px -70px 30px 0px !important;
    float: right;
} */

.networkSchoolSec .topHeading p {
    font-size: 16px;
    font-weight: 400;
    color: #d1d1d1;
    width: 1000px;
    margin: 0 auto;
}

.networkSchoolSec .networkSchoolSlider-outer {
    /* display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center; */
    width: 1265px;
    margin: 0 auto;
    position: relative;
}

.networkSchoolSec .networkSchoolSlider-outer .swiper-slide {
    width: auto !important;
}

.networkSchoolSec .networkSchoolSlider img {
    width: auto;
}

.networkSchoolSec .swiper {
    padding-bottom: 50px;
}

span.swiper-pagination-bullet {
    background-color: #D3D3D3;
    opacity: 1;
    width: 10px;
    height: 10px;
    margin: 0px 5px !important;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #FB4F22;
    position: relative;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active:after {
    content: " ";
    position: absolute;
    width: 14px;
    height: 14px;
    border: 1px solid #FB4F22;
    border-radius: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.networkSchoolSec .networkSchoolSlider-outer:before {
    content: " ";
    position: absolute;
    left: -100px;
    top: calc(50% - 20px);
    width: 45px;
    height: 125px;
    transform: translateY(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../../../public/assets/images/homeImage/gehuIconLeft.svg");
}

.networkSchoolSec .networkSchoolSlider-outer::after {
    content: " ";
    position: absolute;
    right: -100px;
    top: calc(50% - 20px);
    width: 45px;
    height: 125px;
    transform: translateY(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../../../public/assets/images/homeImage/gehuIconRight.svg");
}


.pastProgVideoSec .pastProgVideos .progVedioContent {
    /* display: flex; */
    align-items: center;
    background: #fff;
    padding: 30px;
    margin-top: -5px;
    justify-content: space-between;
    position: relative;
}



.pastProgVideoSec .pastProgVideos .progVedioContent p {
    width: 490px;
}



.pastProgVideoSec .swiper-slide-active .pastProgVideos:after {
    content: " ";
    position: absolute;
    top: 31px;
    right: -9px;
    width: 110px;
    height: 64px;
    z-index: 0;
    transition: all 0.5s ease-in-out;
    background-image: url("../../../public/assets/images/homeImage/orgBgElement.svg");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    /* opacity: 0;
    visibility: hidden; */
}

.pastProgVideoSec .pastProgVideos .progVedioContent:before {
    content: " ";
    position: absolute;
    background-color: #FB4F22;
    height: 65px;
    width: 65px;
    top: -55px;
    left: -10px;
    z-index: 0;
    transition: all 0.5s ease-in-out;
    /* opacity: 0;
    visibility: hidden; */
}

.pastProgVideoSec .progVedioContent,
.pastProgVideoSec .pastProgVideos h4 {
    display: none;
}

.pastProgVideoSec .swiper-slide-active .pastProgVideos .progVedioContent {
    display: flex;
}

.pastProgVideoSec .swiper-slide-active .pastProgVideos h4 {
    display: block;
}

.pastProgVideoSec .swiper-wrapper {
    align-items: center;
    padding-left: 40px;
    padding-bottom: 70px;
}

.pastProgVideoSec .swiper-slide.swiper-slide-active .progVideos {
    width: 747px !important;
    height: 460px !important;
}

.pastProgVideoSec .swiper-slide .progVideos {
    width: 236px !important;
    height: 330px !important;
    object-fit: cover;
    z-index: 3;
    position: relative;
}

.pastProgVideoSec .swiper-slide.swiper-slide-active {
    width: 747px !important;
    height: auto !important;
}

.pastProgVideoSec .swiper-slide {
    width: 236px !important;
    height: 330px !important;
    object-fit: cover;
}









.skillSec {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../public/assets/images/homeImage/skillSecBg.png");
}

.skillSec .topHeading {
    text-align: left;
    padding-bottom: 20px;
}

.skillSec p.subContent {
    font-size: 20px;
    font-weight: 500;
    width: 500px;
}

.skillSec .skills-outer {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    width: 1280px;
}

.skillSec .skills-outer .skills {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}

.skillSec .skills-outer .skills img {
    border-radius: 20px;
    padding-bottom: 10px;
}

.skillSec .skills-outer .skills ul.skillList {
    padding-left: 25px;
    list-style: none;
}

.skillSec .skills-outer .skills ul.skillList li {
    color: #3a3a3a;
    position: relative;
}

.skillSec .skills-outer .skills ul.skillList li+li {
    margin-top: 15px;
}

.skillSec .skills-outer .skills ul.skillList li::before {
    content: " ";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    left: -20px;
    top: 6px;
    background-color: #FB4F22;

}

.intreactionSec {
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;
    line-height: 0;
}

.intreactionSec .intreactionInner {
    position: absolute;
    bottom: 280px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 3;
}

.intreactionSec .intreactionInner h2 {
    line-height: 1;
}

.intreactionSec .intreactionVideo:before {
    content: " ";
    position: absolute;
    top: 4px;
    left: 50%;
    width: 90%;
    height: 100%;
    transform: translateX(-50%);
    background: url("../../../public/assets/images/homeImage/bgOverlap.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
}

.intreactionSec .intreactionVideo:after {
    content: " ";
    position: absolute;
    top: -50px;
    left: 53%;
    width: 91%;
    height: 100%;
    transform: translateX(-50%);
    background: url("../../../public/assets/images/homeImage/interactionIconOver.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
}

.intreactionSec .intreactionVideo video {
    width: 100%;
    height: 794px;
    object-fit: cover;
}

.intreactionSec .swiper-button-prev:after {
    content: " ";
    position: absolute;
    width: 14px;
    height: 20px;
    background-image: url("../../../public/assets/images/leftArrowOrg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.intreactionSec .swiper-button-next::after {
    content: " ";
    position: absolute;
    width: 14px;
    height: 20px;
    background-image: url("../../../public/assets/images/rightArrowOrg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.intreactionSec .swiper-button-prev,
.intreactionSec .swiper-button-next {
    position: absolute;
    margin: 0px;
    top: initial;
    bottom: 200px;
    width: 42px;
    height: 42px;
    background-color: #fff;
    border-radius: 50px;
}

.intreactionSec .swiper-button-next {
    right: calc(50% - 50px);
}

.intreactionSec .swiper-button-prev {
    left: calc(50% - 50px);
}


.testimonialHomeSec .testimonialImg:before {
    background-color: #FB4F22;
    z-index: 0;
}

.testimonialHomeSec .testimonialImg img {
    position: relative;
    z-index: 1;
}

.testimonialHomeSec .testimonialImg:after {
    z-index: 0;
    background-image: url("../../../public/assets/images/homeImage/orgBgElement.svg");
}

.testimonialHomeSec .testimonialImg .testimonialVideo {
    height: 713px;
    object-fit: cover;
    z-index: 0;
    position: relative;
    width: 100%;
}


.partnerHomeSec .swiper-slide {
    filter: saturate(0);
    opacity: 1;
    transition: all 0.5s ease-in-out;
}

.partnerHomeSec .swiper-slide.swiper-slide-prev,
.partnerHomeSec .swiper-slide.swiper-slide-next,
.partnerHomeSec .swiper-slide.swiper-slide-next+.swiper-slide,
.partnerHomeSec .swiper-slide:has(+ .swiper-slide.swiper-slide-prev) {
    opacity: 1;
    transform: none;
}

.partnerHomeSec .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #FB4F22 !important;
}

/* ================================pillar page css============================== */
.pillarBoxSec {
    position: relative;
}

.boderGradient:before {
    content: " ";
    position: absolute;
    left: 50%;
    top: 0px;
    width: 1000px;
    height: 4px;
    border-image: linear-gradient(to left, #fb4f2200, #FB4F22, #fb4f2200) 1;
    border-bottom: 4px solid;
    transform: translateX(-50%);
}

.pillarBoxSec .pillarBox {
    display: flex;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #ffffff80;
    background-color: #212529;
    margin-bottom: 30px;
}

.pillarBoxSec .pillarBox .pillarBoxContent {
    padding: 50px 40px;
}

.container.pillarBox-outer {
    display: grid;
    gap: 50px;
    grid-template-columns: 2fr 1fr;
}

.pillarBoxSec .pillarBox-innerRight .pillarBox {
    display: block;
}

.pillarBoxSec .pillarBox img.leftImg {
    border-radius: 25px 0px 0 25px;
}

.pillarBoxSec .pillarBox img.rightImg {
    border-radius: 0px 25px 25px 0px;
}

.pillarBoxSec .pillarBox img.topImg {
    border-radius: 25px 25px 0 0px;
}

.pillarBoxSec .pillarBox .pillarBoxContent h4 {
    line-height: 1;
}

.programsSec .program-outer {
    display: flex;
    align-items: center;
    padding-top: 60px;
    justify-content: center;
}

.programsSec .program-outer .program-innerLeft {
    padding-right: 50px;
}

.programsSec .program-outer .program-innerLeft img {
    width: 560px;
    border-radius: 30px;
}

.programsSec .program-outer .program-innerRight {
    padding-left: 50px;
}

.programsSec .program-outer .program-innerRight ul.progarmDetailList {
    list-style: none;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1.5fr;
    padding: 15px 25px;
    background-color: #161616;
    border-radius: 10px;
    margin: 30px 0px;
}

.programsSec .program-outer .program-innerRight h4 span {
    padding-right: 10px;
}

.programsSec .program-outer .program-innerRight ul.progarmDetailList li h6 {
    display: flex;
    align-items: center;
}

.programsSec .program-outer .program-innerRight ul.progarmDetailList li span {
    padding-left: 20px;
}

.programsSec .program-outer .program-innerRight ul.progarmDetailList li img {
    margin-right: 10px;
}

.programsSec .program-outer .program-innerRight .registerBtn {
    display: inline-flex;
    justify-content: space-between;
    width: 180px;
}

.readMoreBtn {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    padding-left: 20px;
}

.programsSec .program-outer .program-innerRight p {
    max-width: 550px;
}

.opportunitiesSec .opportunities-outer {
    display: grid;
    gap: 0px;
    grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid #fff;
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper {
    border: 1px solid #fff;
    position: relative;
    line-height: 0;
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper>img {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper:hover img {
    filter: none;
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper .contentWrapper {
    position: absolute;
    bottom: 0px;
    padding: 25px;
    z-index: 2;
    line-height: 1.2;
}

.opportunitiesSec .opportunities-wrapper:before {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 150px;
    opacity: 50%;
    z-index: 1;
}

.opportunitiesSec .opportunities-wrapper.yellowGradient:before {
    background: linear-gradient(180deg, rgba(251, 194, 16, 0) 0%, rgba(251, 194, 16, 1) 90%, rgba(251, 194, 16, 1) 100%);
}

.opportunitiesSec .opportunities-wrapper.purpleGradient:before {
    background: linear-gradient(180deg, rgba(102, 51, 153, 0) 0%, rgba(102, 51, 153, 1) 90%, rgba(102, 51, 153, 1) 100%);
}

.opportunitiesSec .opportunities-wrapper.orgGradient:before {
    background: linear-gradient(180deg, rgba(255, 95, 21, 0) 0%, rgba(255, 95, 21, 1) 90%, rgba(255, 95, 21, 1) 100%);
}

.opportunitiesSec .opportunities-wrapper.orgGradient>img {
    height: 596px;
}

.opportunitiesSec .opportunities-wrapper.blueGradient:before {
    background: linear-gradient(180deg, rgba(46, 187, 223, 0) 0%, rgba(46, 187, 223, 1) 90%, rgba(46, 187, 223, 1) 100%);
}

.opportunitiesSec .opportunities-wrapper.blueGradient>img {
    height: 320px;
}

.opportunitiesSec .opportunities-wrapper.redGradient:before {
    background: linear-gradient(180deg, rgba(229, 36, 60, 0) 0%, rgba(229, 36, 60, 1) 90%, rgba(229, 36, 60, 1) 100%);
}

.opportunitiesSec .opportunities-wrapper.redGradient>img {
    height: 388px;
}

.opportunitiesSec .opportunities-wrapper.greenGradient:before {
    background: linear-gradient(180deg, rgba(76, 158, 55, 0) 0%, rgba(76, 158, 55, 1) 90%, rgba(76, 158, 55, 1) 100%);
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper .contentWrapper a.readMoreBtn {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper .contentWrapper a.readMoreBtn img {
    padding-left: 10px;
    width: 25px;
}


.callToActionPillar .callToAction-inner {
    background-image: url("../../../public/assets/images/pillarPage/readyBgOrg.png");
}

.callToActionPillar .callToAction-inner .registerBtn {
    display: inline-flex;
    justify-content: space-between;
    width: 180px;
    background-color: #fff;
    color: #FB4F22;
}

.advenBootcampSec .advenBootcamp-outer {
    display: flex;
    align-items: center;
}

.advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerLeft img {
    width: 560px;
    border-radius: 30px;
}

.advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerLeft {
    padding-right: 50px;
    width: 50%;
    text-align: center;
}

.advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerRight {
    padding-left: 50px;
    width: 50%;
}

.advenBootcampSec .advenBootcamp-outer ul.advenBootcampList {
    padding-left: 25px;
    list-style: none;
    width: 390px;
}

.advenBootcampSec .advenBootcamp-outer ul.advenBootcampList li {
    position: relative;
}

.advenBootcampSec .advenBootcamp-outer ul.advenBootcampList li+li {
    margin-top: 20px;
}

.advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerRight>p {
    line-height: 1.7;
}

.advenBootcampSec .advenBootcamp-outer ul.advenBootcampList li::before {
    content: " ";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    left: -20px;
    top: 6px;
    background-color: #FB4F22;
}


/* =====================Network page css-------------------------------- */
.network-school {
    padding-top: 165px !important;
}

.network-school p {
    width: 850px;
    margin: 0 auto;
    text-align: center;
}

.testimonialvideo .pastProgVideos {
    position: relative;
}

.testimonialvideo .testimonialvideoInner .swiper-slide.swiper-slide-active .pastProgVideos:after {
    top: -8px;
    right: -10px;
}

.testimonialvideo .testimonialvideoInner .swiper-slide.swiper-slide-active .pastProgVideos:before {
    content: " ";
    position: absolute;
    background-color: #FB4F22;
    height: 65px;
    width: 65px;
    bottom: -6px;
    left: -10px;
    z-index: 0;
    transition: all 0.5s ease-in-out;
}

.testimonialvideo .testimonialvideoInner {
    overflow: visible;
}

.testimonialvideo .testimonialvideoInner .swiper-wrapper {
    align-items: end;
    padding-left: 0;
}

.bgwhite {
    background-color: #fff;
    border-radius: 14px;
    padding: 70px;
}

.school-logo {
    position: relative;
}

.school-logo.boderGradient:before {
    height: auto;
}

.school-logo-outer {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);
}

.benefits .program-outer {
    justify-content: space-between;
}

.benefits .program-innerRight {
    padding-left: 0px !important;
    padding-right: 50px;
}

.benefits .program-innerLeft {
    padding-right: 0px !important;
    padding-left: 50px;
}

.benefits .program-outer .program-innerRight>p {
    padding-bottom: 45px;
}

.benefits .program-innerRight ul.freestructure {
    color: #fff;
    list-style: number;
    margin-left: 20px;
    padding-bottom: 45px;
}

.benefits .program-innerRight .freestructure li+li {
    padding-top: 5px !important;
}

.benefits .program-innerRight a.registerBtn {
    margin-right: 10px;
    width: auto !important;
}

.download-brochure {
    padding: 11px 35px;
    color: #fff;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    line-height: 1;
    border: 2px solid #fff;
}

.download-brochure .widthAuto {
    margin-right: 10px;
}

.joinour-chain .contactPopup {
    width: auto;
    background-color: #000;
    padding: 0;
}

.joinour-chain .contactDetail-outer {
    display: flex;
    align-items: center;
}

.joinour-chain .contactForm {
    background-color: #212529 !important;
}

.loaction {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
}

.benefits .program-outer {
    padding-top: 0px;
}

.benefits h3 {
    width: 526px;
}

select:focus {
    outline: none;
}

.joinour-chain .contactForm input,
.joinour-chain .contactForm textarea {
    height: 55px;
    padding: 15px 20px;
}

.joinour-chain .contactForm textarea {
    height: 110px !important;
}

select {
    width: 100%;
    height: 70px;
    padding: 20px 40px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    color: #b7b3b0;
    border: none;
    font-family: 'Euclid Circular A';
}

/* =======================================Contact Popup css--------------------------------------------- */

html body .popup-overlay {
    background: rgba(0, 0, 0, 0.8);
}
html body .popup-content {
    margin: auto;
    background: #fff;
    width: 100%;
    padding: 5px;
    border: 1px solid #d7d7d7;
    max-width: 750px;
    /* overflow: hidden; */
    padding: 0;
    border: navajowhite;
    border-radius: 15px;
}
html body .popup-content .bgBlack{
    border-radius: 15px;
}
button.close {
    position: absolute;
    right: -9px;
    top: -9px;
    background: #fff;
    border: none;
    border-radius: 50px;
    width: 28px;
    height: 28px;
    font-size: 25px;
    cursor: pointer;
}


.contactPopup {
    padding: 20px;
    display: grid;
    gap: 70px;
    /* grid-template-columns: repeat(2, 1fr); */
    width: auto;
    margin: 0 auto;
    border-radius: 20px;
}

.contactPopup .contactDetail-outer {
    padding: 30px 20px;
}

.contactPopup .contactDetail-outer .contactDetail a.textEmail {
    background-color: #2D2D2D;
    padding: 5px 10px;
    border-radius: 6px;
}

.contactPopup .contactDetail-outer .contactDetail a.textEmail span {
    display: inline-flex;
    align-items: center;
}

.contactPopup .contactDetail-outer .contactDetail a.textEmail span img {
    margin-right: 5px;
}

.contactPopup .contactDetail-outer>img {
    width: 236px;
}


.contactPopup .contactDetail-outer .contactDetail {
    width: 445px;
    padding-bottom: 10px;
}

.contactPopup .contactDetail-outer .contactDetail h3 {
    padding: 0 50px 10px 0px;
}

.contactPopup .contactForm {
    background-color: #000;
    border-radius: 20px;
    padding: 40px;
}

.contactPopup .contactForm label {
    display: none;
}

.contactPopup .contactForm input,
.contactPopup .contactForm textarea {
    width: 100%;
    height: 70px;
    padding: 20px 40px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    color: #000;
    border: none;
    font-family: 'Euclid Circular A'

}

.contactPopup .contactForm input:focus,
.contactPopup .contactForm textarea:focus {
    border: none;
    outline: none;
}

.contactPopup .contactForm input::placeholder,
.contactPopup .contactForm textarea::placeholder {
    color: #00000066;
    font-family: 'Euclid Circular A'
}

.contactPopup .contactForm textarea {
    height: 155px;
}

.contactPopup .contactForm button.formSubmit {
    background-color: #FB4F22;
    padding: 14px 40px;
    color: #fff;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    border: none;
}














@media screen and (max-width: 1440px) {
    .container {
        max-width: 1200px;
        margin: 0 auto;
    }

    .oxfordBanner .banner-content h1,
    .secTitle {
        font-size: 42px;
    }

    p {
        font-size: 17px;
    }

    .fs-18 {
        font-size: 17px !important;
    }

    .secTitle {
        padding-bottom: 50px;
    }

    .whyOxfordSec {
        padding: 100px 20px !important;
    }

    .whyOxfordSec .oxford-box h3 {
        width: 100%;
        padding-bottom: 10px;
    }

    .whyOxfordSec .column .oxford-box p {
        padding-right: 0;
    }

    .eventSec,
    .conference,
    .partnerSec,
    .callToAction {
        padding: 80px 20px;
    }

    .confere-box h3 {
        padding-bottom: 15px;
    }

    .testimonialSec {
        padding: 50px 20px 80px;
    }

    .reviewSec {
        padding: 80px 0 0;
    }

    .faqSec {
        padding: 0px 20px 80px;
    }

    button.join {
        padding: 13px 30px;
    }

    h3 {
        font-size: 22px;
    }

    .eventSec .eventBox .eventTitle {
        font-size: 24px;
    }

    .eventSec .eventBox p {
        font-size: 16px;
    }

    /* .eventSec .eventBox {
        width: 100%;
    } */

    .eventSec .eventBox .eventContentBox {
        min-height: 215px;
    }

    .venueSpotSec .venueSpot-inner img {
        width: 700px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent {
        padding: 40px 40px 40px 110px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-thumbnail {
        width: 120px;
    }

    .eventSec .swiper-button-next:after,
    .eventSec .swiper-button-prev:after {
        width: 18px;
        height: 18px;
    }

    .eventSec .swiper-button-prev,
    .eventSec .swiper-button-next {
        width: 40px;
        height: 40px;
    }

    .eventSec .swiper-button-prev {
        right: 65px;
    }

    .eventSec .eventBox .eventDate {
        font-size: 26px;
        padding: 13px;
    }

    .eventSec .eventBox .eventDate span {
        font-size: 14px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent h2 {
        font-size: 30px;
        padding-bottom: 20px;
    }

    .testimonialSec .testimonialImg .titleWatch {
        font-size: 28px;
    }

    .reviewSec .reviewContent {
        width: 480px;
    }

    .reviewSec .swiper-button-prev {
        left: calc(50% - 215px);
        bottom: 72px;
    }

    .reviewSec .swiper-button-next {
        right: calc(50% - 215px);
        bottom: 72px;
    }

    .joinSec .logoImage img {
        width: 300px;
    }

    .joinSec .content-wrapperCol .content-wrapper .contentTitle {
        font-size: 30px;
        margin-bottom: 25px;
    }

    .callToAction .callToAction-inner h5 {
        font-size: 30px;
    }

    .faqSec .faqInner {
        padding: 60px;
    }

    .faqSec .faqInnerRight {
        width: 60%;
        padding-left: 50px;
    }

    .faqSec .faqInnerLeft {
        width: 40%;
        margin-right: 20px;
        padding: 50px;
    }

    .callToAction .callToAction-inner .callToActionRight p {
        padding-bottom: 40px;
    }

    .registerBtn {
        font-size: 17px;
    }
}

@media screen and (max-width: 1024px) {

    .oxfordBanner .banner-content h1,
    .secTitle {
        font-size: 36px;
    }

    .introduction p {
        width: 100%;
    }

    p {
        font-size: 17px;
    }

    h3 {
        font-size: 22px;
    }

    .secTitle {
        padding-bottom: 40px;
    }

    .intro-section,
    .eventSec,
    .conference,
    .partnerSec,
    .callToAction {
        padding: 60px 20px;
    }

    .whyOxfordSec {
        padding: 80px 20px !important;
    }

    .whyOxfordSec .oxford-box h3 {
        width: 100%;
        padding-right: 15px;
    }

    .whyOxfordSec .inner-box {
        border-radius: 30px;
        padding: 40px;
    }

    .whyOxfordSec .row {
        gap: 60px;
    }

    .whyOxfordSec .column .oxford-box p {
        padding-right: 0;
    }

    .whyOxfordSec .column .oxford-box:first-child {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    .confere-detail {
        padding: 0px;
    }

    .confere-box {
        border-radius: 20px;
        padding: 25px 30px 25px 25px;
        margin-bottom: 30px;
    }

    .confere-box h3,
    .conference .confere-reg h3 {
        padding-bottom: 15px;
    }

    .conference .confere-detail .confere-box:before {
        right: 20px;
        width: 10px;
    }

    .conference .confere-detail .row {
        gap: 30px;
    }

    .conference .confere-box span.bgColorText {
        font-size: 16px;
        padding: 3px 7px;
    }

    .conference .confere-reg p {
        padding-bottom: 35px;
    }

    .registerBtn {
        padding: 12px 25px;
        font-size: 16px;
    }

    .conference .confere-reg {
        border-radius: 20px;
        padding: 25px;
    }

    .eventSec .swiper-button-prev,
    .eventSec .swiper-button-next {
        top: -50px;
        width: 35px;
        height: 35px;
    }

    .eventSec .swiper-button-prev {
        right: 60px;
    }

    .eventSec .swiper {
        padding: 0 0px;
    }

    .eventSec .eventBox img {
        border-radius: 20px 20px 0 0;
        height: 240px;
    }

    .eventSec .eventBox .eventTitle {
        font-size: 22px;
    }

    .eventSec .eventBox .eventContentBox {
        padding: 40px 20px 30px;
        min-height: 191px;
    }

    .eventSec .eventBox .eventDate {
        font-size: 22px;
        padding: 10px;
    }

    .eventSec .eventBox .eventDate span {
        font-size: 13px;
        padding-top: 6px;
    }

    .partnerSec .swiper-pagination .swiper-pagination-bullet {
        width: 7px;
        height: 7px;
        margin: 3px !important;
    }

    .partnerSec .swiper-pagination .swiper-pagination-bullet-active:before {
        width: 10px;
        height: 10px;
    }

    .venueSpotSec .venueSpot-inner {
        display: block;
    }

    .venueSpotSec {
        padding: 50px 20px 30px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent {
        margin: 0px;
        border: none;
        padding: 40px 0px 0px;
    }

    .venueSpotSec .venueSpot-inner img {
        width: 100%;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-slides {
        border-radius: 20px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-thumbnail {
        width: 120px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent h2 {
        font-size: 28px;
    }

    .testimonialSec {
        padding: 30px 20px 60px;
    }

    .testimonialSec .testimonialImg .titleWatch {
        font-size: 24px;
    }

    .reviewSec {
        padding: 60px 0 0;
    }

    .reviewSec .reviewContent {
        padding: 25px 50px;
        bottom: 20px;
        width: 422px;
    }

    .reviewSec .swiper-button-prev {
        left: calc(50% - 185px);
    }

    .reviewSec .swiper-button-next {
        right: calc(50% - 185px);
    }

    .reviewSec .swiper-slide img {
        min-height: 500px;
        object-fit: cover;
    }

    .reviewSec .swiper-button-prev:after,
    .reviewSec .swiper-button-next::after {
        width: 12px;
        height: 18px;
    }

    .reviewSec .swiper-button-prev,
    .reviewSec .swiper-button-next {
        bottom: 46px;
        width: 12px;
        height: 18px;
    }

    .reviewSec .reviewContent p span {
        font-size: 18px;
    }

    .joinSec .logoImage {
        width: 40%;
    }

    .joinSec .content-wrapperCol {
        width: 60%;
        padding-left: 40px;
    }

    .joinSec {
        padding: 40px 20px;
    }

    .joinSec .logoImage img {
        width: 220px;
    }

    .joinSec .content-wrapperCol .content-wrapper .contentTitle {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .callToAction .callToAction-inner {
        padding: 30px 50px;
        border-radius: 25px;
    }

    .callToAction .callToAction-inner h5 {
        font-size: 26px;
    }

    .callToAction .callToAction-inner .callToActionRight p {
        padding-bottom: 20px;
        width: 100%;
    }

    .faqSec .faqInner p {
        padding-bottom: 40px;
    }

    .faqSec .faqInner {
        display: block;
        padding: 40px;
        border-radius: 20px;
    }

    .faqSec .faqInnerLeft img {
        width: 150px;
    }

    .faqSec .faqInnerLeft {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 50px;
        padding: 30px;
    }

    .faqSec .faqInnerRight {
        width: 100%;
        padding-left: 0px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__button {
        padding: 30px 20px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__panel {
        padding: 0px 40px 30px 60px;
    }

}

@media screen and (max-width: 767px) {
    .banner-content {
        bottom: 20px;
    }

    p {
        font-size: 15px;
    }

    h3 {
        font-size: 20px;
    }

    .column {
        width: 100%;
    }

    .oxfordBanner .banner-content h1,
    .secTitle {
        font-size: 28px;
    }

    .secTitle {
        padding-bottom: 30px;
    }

    .intro-section,
    .eventSec,
    .conference,
    .partnerSec,
    .callToAction {
        padding: 40px 20px;
    }

    .whyOxfordSec {
        padding: 60px 20px !important;
    }

    .whyOxfordSec .row {
        display: block;
    }

    .whyOxfordSec .inner-box {
        border-radius: 20px;
        padding: 30px;
    }

    .whyOxfordSec .inner-box .column:first-child {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-image: linear-gradient(to left, #fff, #66666680, #666666cc, #666666) 1;
        border-bottom: 2px solid;
    }

    .conference .confere-detail .row {
        display: block;
    }

    .confere-box {
        border-radius: 15px;
        margin-bottom: 30px !important;
    }

    .conference .confere-reg {
        border-radius: 15px;
        padding: 25px;
    }

    .eventSec .eventBox img {
        border-radius: 15px 15px 0 0;
        height: 220px;
    }

    .eventSec .eventBox {
        border-radius: 15px;
    }

    .eventSec .swiper {
        padding: 0 0px 60px;
    }

    .eventSec .eventBox .eventTitle {
        font-size: 20px;
    }

    .eventSec .swiper-button-prev,
    .eventSec .swiper-button-next {
        top: inherit;
        width: 35px;
        height: 35px;
        bottom: 0px;
    }

    .eventSec .swiper-button-prev {
        right: inherit;
        left: calc(50% - 45px);
    }

    .eventSec .swiper-button-next {
        right: calc(50% - 45px);
    }

    .eventSec .eventBox .eventContentBox {
        min-height: auto;
    }

    .partnerSec .swiper-pagination {
        bottom: -20px !important;
    }

    .partnerSec .swiper-pagination .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-thumbnail {
        width: 80px;
        margin: 0 5px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-thumbnails-wrapper {
        left: 10px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-slides {
        border-radius: 15px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-left-nav,
    .image-gallery-right-nav {
        width: 30px;
        height: 30px;
        line-height: 0px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 20px;
        width: 20px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent {
        padding: 30px 0px 0px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent h2 {
        font-size: 24px;
        padding-bottom: 15px;
    }

    .venueSpotSec {
        padding: 40px 20px 40px;
    }

    .testimonialSec {
        padding: 40px 20px 40px;
    }

    .testimonialSec .testimonialImg .titleWatch {
        font-size: 22px;
    }

    .reviewSec {
        padding: 40px 0 0;
    }

    .reviewSec .swiper-slide img {
        min-height: 450px;
    }

    .reviewSec .reviewContent {
        padding: 20px 30px;
        bottom: 30px;
        width: 90%;
    }

    .reviewSec .reviewContent .reviewTitle {
        max-width: 170px;
        margin: 0 auto;
    }

    .reviewSec .reviewContent p {
        font-size: 14px;
    }

    .reviewSec .reviewContent p span {
        font-size: 16px;
    }

    .reviewSec .swiper-button-prev,
    .reviewSec .swiper-button-next {
        bottom: 60px;
        width: 12px;
        height: 18px;
    }

    .reviewSec .swiper-button-prev {
        left: 40px;
    }

    .reviewSec .swiper-button-prev:after,
    .reviewSec .swiper-button-next::after {
        width: 10px;
        height: 16px;
    }

    .reviewSec .swiper-button-next {
        right: 40px;
    }

    .joinSec .row {
        display: block;
    }

    .joinSec .logoImage {
        width: 100%;
        padding: 0px 30px
    }

    .joinSec .logoImage img {
        width: 100%;
    }

    .joinSec .content-wrapperCol {
        width: 100%;
        padding: 0;
    }

    .joinSec .content-wrapperCol .content-wrapper .contentTitle {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .joinSec .content-wrapperCol .content-wrapper .contentTitle:after {
        bottom: 5px;
        height: 3px;
    }

    .callToAction .callToAction-inner .callToActionLeft p {
        width: 100%;
        padding: 0px 0px 15px;
    }

    .callToAction .callToAction-inner {
        padding: 25px;
        border-radius: 15px;
    }

    .callToAction .callToAction-inner h5 {
        font-size: 22px;
    }

    .callToAction .callToAction-inner .row {
        display: block;
    }

    .registerBtn {
        font-size: 14px;
    }

    .faqSec .faqInner {
        padding: 20px;
        border-radius: 15px;
    }

    .faqSec .faqInnerLeft {
        margin-bottom: 30px;
        padding: 20px;
    }

    .faqSec .faqInnerLeft img {
        width: 120px;
    }

    .faqSec .faqInner p {
        padding-bottom: 30px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__button {
        padding: 20px 25px 20px 10px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__button .faqTitle,
    .faqSec .faqInnerRight .faqBlock .accordion .accordion__panel p {

        font-size: 14px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__button .faqTitle img {
        margin-right: 10px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__panel {
        padding: 0px 20px 20px 44px;
    }

    .header-btn {
        margin-left: 20px;
    }

}