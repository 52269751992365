/* Import Swiper styles */
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'swiper/css/scrollbar';
@import "~react-image-gallery/styles/css/image-gallery.css";
@import 'react-accessible-accordion/dist/fancy-example.css';


.partnerSec .swiper {
    overflow: visible;
    padding-bottom: 50px;
}

.partnerSec .swiper-pagination {
    bottom: -20px !important;
}

.partnerSec .swiper-pagination .swiper-pagination-bullet-active {
    background: #072654 !important;
    position: relative;
}

.partnerSec .swiper-pagination .swiper-pagination-bullet {
    background: #D3D3D3;
    width: 10px;
    height: 10px;
    margin: 6px !important;

}

.partnerSec .swiper-pagination .swiper-pagination-bullet-active:before {
    content: " ";
    position: absolute;
    left: 50%;
    top: 50%;
    border: 1px solid #072654;
    width: 14px;
    height: 14px;
    border-radius: 50px;
    transform: translate(-50%, -50%);
}

.eventSec .swiper {
    overflow: visible;
    padding: 0 20px;
}

.eventSec .container {
    overflow: hidden;
}

.eventSec .swiper-button-prev,
.eventSec .swiper-button-next {
    top: -60px;
    background: #fff;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    z-index: 2;
}

.eventSec .swiper-button-prev {
    left: initial;
    right: 75px;
}

.eventSec .swiper-button-prev:after {
    font-size: 0px;
    background-image: url("/public/assets/images/leftslide.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
}

.eventSec .swiper-button-next:after {
    font-size: 0px;
    background-image: url("/public/assets/images/rightslide.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
}

.testimonialSec .swiper {
    overflow: visible;
}

.partnerSec .swiper-slide {
    filter: grayscale(100%);
    opacity: 0.2;
    transition: all 0.5s ease-in-out;
}

.partnerSec .swiper-slide.swiper-slide-prev,
.partnerSec .swiper-slide.swiper-slide-next {
    opacity: 1;
    transform: scale(1);
}

.partnerSec .swiper-slide.swiper-slide-next+.swiper-slide {
    opacity: 0.5;
    transform: scale(0.95);
}

.partnerSec .swiper-slide:has(+ .swiper-slide.swiper-slide-prev) {
    opacity: 0.5;
    transform: scale(0.95);
}

.partnerSec .swiper-slide.swiper-slide-active {
    filter: none;
    opacity: 1;
    transform: scale(1.12);
}

/* ------------------------Community Challenges  page css------------------------ */
.communityIntro-section,
.communityFlameUni {
    background-color: #F0F0F0 !important;
}

.communityWhyOxfordSec {
    background-image: url("../public/assets/images/org-bg.png") !important;
}

.communityConference .confere-box {
    border: 3px solid #F9A21B;
}

.communityConference .confere-detail .confere-box:before {
    background-color: #F9A21B !important;
}

.communityConference .confere-box span.bgColorText {
    background-color: #000000 !important;
}

.communityConference .confere-reg p span {
    background-color: #ffffff !important;
    color: #F9A21B !important;
}

.communityConference .confere-reg h3,
.communityConference .confere-reg p {
    color: #ffffff !important;
}

.communityConference .registerBtn {
    background-color: #ffffff;
    color: #F9A21B;
}

.communityFlameUni .communityFlameUni-outer {
    display: grid;
    gap: 170px;
    grid-template-columns: 0.74fr 1.5fr;
}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerLeft img {
    padding-bottom: 40px;
    display: block;
}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerLeft h3 {
    display: inline-block;
    position: relative;
    padding-bottom: 15px;
}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerLeft h3:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 30px;
    background-color: #000;
}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight .universityImg {
    border-radius: 50px;
    width: 767px;
    position: relative;
    z-index: 1;

}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight {
    position: relative;
}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight:before {
    content: " ";
    position: absolute;
    right: 0;
    bottom: -30px;
    border: 4px solid #F9A21B;
    border-radius: 50px;
    width: 767px;
    height: 520px;
    z-index: 0;
}

.experienceSec .swiper-slide {
    width: 434px !important;
}

.experienceSec .experienceBox {
    background-color: #fff;
    border: 2px solid #F9A21B;
    border-radius: 30px;
    padding: 35px;
    box-shadow: 0 0 20px 0 rgba(0 0 0/15%);
    transition: all 0.5s ease-in-out;
    /* width: 434px; */
}

.experienceSec .experienceBox h5 {
    line-height: 1.3;
}

.experienceSec .experienceBox-inner {
    display: flex;
}

.experienceSec .experienceBox-inner img {
    padding: 20px 25px;
    border-radius: 50%;

}

.experienceSec .experienceBox-inner img.nonActive {
    background-color: #F9A21B;

}

.experienceSec .experienceBox-inner img.active {
    background-color: #fff;
    display: none;

}

.experienceSec .swiper-slide-active .experienceBox img.nonActive {
    display: none;
}

.experienceSec .swiper-slide-active .experienceBox img.active {
    display: block;
}

.experienceSec .swiper-slide-active .experienceBox {
    background-color: #F9A21B;
    border: 2px solid #fff;
    box-shadow: 0 0 20px 0 rgba(0 0 0/15%);
}

.experienceSec .swiper-slide-active .experienceBox h5,
.experienceSec .swiper-slide-active .experienceBox p {
    color: #fff !important;
}

.experienceSec .swiper-slide-active .experienceBox-inner img {
    background-color: #fff !important;

}

.experienceSec .swiper {
    overflow: visible;
    padding-bottom: 60px;
}


.communityPartnerSec .swiper-pagination .swiper-pagination-bullet,
.experienceSec .swiper-pagination .swiper-pagination-bullet {
    background: #f0f0f0;
}

.communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active {
    background: #F9A21B !important;
}

.communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active:before,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active:before {
    border: 1px solid #F9A21B;
}

.rewardSec {
    background-color: #F0F0F0;
}

.rewardSec .priceHeading {
    font-size: 40px;
    line-height: 1;
    background-color: #F9A21B;
    display: inline-block;
    padding: 15px 65px 85px;
    border-radius: 65px 65px 0 0;
}

.rewardSec .reward-outer {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 30px;
}

.rewardSec .rewardInner {
    text-align: center;
    transform: scale(0.85);
}

.rewardSec .innnerContent h6 {
    font-size: 22px;
    line-height: 1.3;
}

.rewardSec .rewardInner.rewardInnerCenter {
    transform: scale(1.15);
}

.rewardSec .innnerContent {
    border: 3px solid #F9A21B;
    border-radius: 22px;
    padding: 20px 40px 30px;
    background-color: #fff;
    position: relative;
    margin-top: -80px;
}

.rewardSec .innnerContent .innnerContentTop {
    border-image: linear-gradient(to left, #ffffff00, #00000080, #000000, #00000080, #fb4f2200) 1;
    border-bottom: 2px solid;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.rewardSec .rewardInner .priceTitle {
    font-size: 28px;
    background-image: url("../public/assets/images/rewardFrame.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    display: inline-block;
    width: 375px;
    height: 60px;
    margin-top: -18px;
    position: relative;
    line-height: 2;
}
.blackBarSec{
    background-color: #000;
    padding: 30px 20px;
    text-align: center;
}
.rewardSecondSec{
    background-color: #F0F0F0;
}
.rewardSecondSec h2 {
    text-align: center;
}
.rewardSecondSec .program-outer .program-innerRight ul.progarmDetailList {
    background-color: #F9A21B !important;
}

.rewardSecondSec .program-outer .program-innerRight .registerChall {
    background-color: #000;
    width: 310px !important;
    font-weight: 400;
    cursor: pointer;
    padding: 15px 30px;
}






.communityCallToAction .callToAction-inner {
    background-image: url("../public/assets/images/readyBgorg.png") !important;
}

.communityCallToAction .registerBtn {
    background: #fff;
    color: #F9A21B;
}

.communityFaqSec .faqInnerLeft {
    background: #000;
    background-image: none !important;
}

.communityFaqSec .faqInner {
    background: #F0F0F0 !important;
}

